import {
  Button,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axiosFetch from "../configs/axiosConfig";

export default function SignupModal({ plan: mentorship }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [input, setInput] = useState({
    fullName: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState({});
  // Define the mutation using React Query
  const { isError, mutate, isLoading } = useMutation(
    async (userData) => {
      const { fullName: name, password, email } = userData;
      return await axiosFetch().post("/signup", { name, password, email });
    },
    {
      onSuccess: () => {
        // Navigate to login page on success
        navigate("/account/login");
      },
      onError: (error) => {
        const { data, status } = error.response;

        if (status == 409) {
          setError((prev) => ({
            ...prev, email: "This email address is already in use."
          }))
        } else {
          setError(data)
        }
      },
    }
  );
  console.log(isLoading)
  const handleSubmit = async (event) => {
    event.preventDefault();

    mutate(input);
  };

  const formData = (event) => {
    const { value, name } = event.target;
    setInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <form onSubmit={handleSubmit}>
      <VStack
        spacing={4}
        border="1px"
        borderColor="gray.300"
        borderRadius="md"
        p={6}>
        <FormControl isInvalid={error?.name} width="100%">
          <FormLabel htmlFor="fullName" color="#fff">
            Full Name
          </FormLabel>
          <Input
            id="fullName"
            isRequired
            backgroundColor="gray.700"
            color="gray.100"
            placeholder="Name"
            type="text"
            value={input.fullName}
            name="fullName"
            onChange={formData}
            borderRadius="md"
            _placeholder={{
              color: "gray.300",
            }}
            _focus={{
              borderColor: "blue.300",
            }}
          />
          <FormErrorMessage>
            <FormErrorIcon />
            {error?.name}
          </FormErrorMessage>
        </FormControl>
        <FormControl id="email" isInvalid={error?.email} width="100%">
          <FormLabel htmlFor="email" color="#fff">
            Email
          </FormLabel>
          <Input
            id="email"
            isRequired
            backgroundColor="gray.700"
            color="gray.100"
            type="email"
            placeholder="email"
            value={input.email}
            name="email"
            onChange={formData}
            borderRadius="md"
            _placeholder={{
              color: "gray.300",
            }}
            _focus={{
              borderColor: "blue.300",
            }}
          />
          <FormErrorMessage>
            <FormErrorIcon />
            {error?.email}
          </FormErrorMessage>
        </FormControl>
        <FormControl id="password" isInvalid={error?.password} width="100%">
          <FormLabel htmlFor="password" color="#fff">
            Password
          </FormLabel>
          <InputGroup>
            <Input
              id="password"
              isRequired
              placeholder="password"
              backgroundColor="gray.700"
              color="gray.100"
              type={showPassword ? "text" : "password"}
              value={input.password}
              name="password"
              onChange={formData}
              borderRadius="md"
              _placeholder={{
                color: "gray.300",
              }}
              _focus={{
                borderColor: "blue.300",
              }}
            />
            <InputRightElement>
              <Button
                size="sm"
                onClick={handleTogglePassword}
                fontSize="sm"
                colorScheme="blue"
                variant="ghost">
                {!showPassword ? (
                  <IconButton size={"sm"} icon={<FaEye />} />
                ) : (
                  <IconButton size={"sm"} icon={<FaEyeSlash />} />
                )}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            <FormErrorIcon />
            {error?.password}
          </FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          colorScheme="whatsapp"
          width="100%"
          isLoading={isLoading}>
          Submit
        </Button>
      </VStack>
    </form>
  );
}
